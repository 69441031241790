import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { TrackingService } from '../services/tracking.service';

@Component({
    selector: 'app-tiny',
    templateUrl: './tiny.component.html',
    styleUrls: []
})
export class TinyComponent implements OnInit, AfterViewInit {

    private tiny: string;

    constructor(private route: ActivatedRoute,
                @Inject(DOCUMENT) private _document,
                private trackingService: TrackingService) {
        this.route.params.subscribe(params => {
            this.tiny = params['tinyurl'];
        });
    }

    ngOnInit() {
        this.smsEvent();
        const URL_API_TINY = environment.URL_API_TINY;
        location.href = URL_API_TINY + `${this.tiny}`;
        this._document.location.href = URL_API_TINY + `${this.tiny}`;
    }

    smsEvent() {
        this.trackingService.setHash(this.tiny);
        this.trackingService.smsEvent();
    }

    ngAfterViewInit(): void {
        this.smsEvent();
    }

}
