import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { UnsetFineAction } from 'src/app/store/fine.actions';
import { appConfig } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private token: string;
    private hash: string;
    private readonly nameSess: string;
    private readonly dataSess: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private http: HttpClient,
                private toastr: ToastrService,
                private store: Store<AppState>) {
        this.nameSess = appConfig.hash_session;
        this.dataSess = appConfig.data_session;
        this.loadStorage();
    }

    login(hash: string, key: string) {
        const URL_API = environment.URL_API;
        const url_call = URL_API + `checkcodigo/${ hash }`;
        if (this.islogged()) {
            this.logout();
        }
        const input = new FormData();
        input.append('codigo', key);
        return this.http.post<any>(url_call, input)
            .pipe(map((res: any) => {
                if (res && res.TOKEN) {
                    this.token = res.TOKEN;
                    this.hash = hash;
                    this.saveStorage();
                    this.loadStorage();
                }
            }));
    }

    logout() {
        localStorage.removeItem(this.nameSess);
        localStorage.removeItem(this.dataSess);
        this.store.dispatch(new UnsetFineAction());
    }

    getToken() {
        return this.token;
    }

    getHash() {
        if (localStorage.getItem(this.nameSess)) {
            this.hash = JSON.parse(atob(localStorage.getItem(this.nameSess))).hash;
        } else {
            this.route.params.subscribe(params => {
                this.hash = params['hash'];
            });
        }
        return this.hash;
    }

    loadStorage() {
        if (localStorage.getItem(this.nameSess)) {
            this.token = JSON.parse(atob(localStorage.getItem(this.nameSess))).token;
            this.hash = JSON.parse(atob(localStorage.getItem(this.nameSess))).hash;
        } else {
            this.token = '';
            this.hash = '';
        }
        this.saveStorage();
    }

    saveStorage() {
        const ses = btoa(JSON.stringify({hash: this.hash, token: this.token}));
        localStorage.setItem(this.nameSess, ses);
    }

    islogged() {
        this.loadStorage();
        return (this.token.length > 5 && this.hash.length > 5);
    }
}

