import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { ResponsiveModule } from 'ngx-responsive';
import { ToastrModule } from 'ngx-toastr';
import { NgxPopperModule } from 'ngx-popper';
import { HeaderComponent } from './header/header.component';
import { FooterWidgetsComponent } from './footer-widgets/footer-widgets.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { PopupComponent } from './popup/popup.component';
import { HeaderappComponent } from './headerapp/headerapp.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FooterBottomPrivateComponent } from './footer-bottom-private/footer-bottom-private.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { NgxSkeletonModule } from 'ngx-skeleton';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        NgbModule,
        MomentModule,
        ResponsiveModule.forRoot(),
        NgxPopperModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            disableTimeOut: true,
            closeButton: true
        }),
        ReactiveFormsModule,
        ScrollToModule,
        NgxSkeletonModule
    ],
    declarations: [
        HeaderComponent,
        FooterWidgetsComponent,
        FooterBottomComponent,
        PopupComponent,
        HeaderappComponent,
        FooterBottomPrivateComponent,
        SkeletonComponent
    ],
    exports: [
        HeaderComponent,
        FooterWidgetsComponent,
        FooterBottomComponent,
        PopupComponent,
        HeaderappComponent,
        FooterBottomPrivateComponent,
        SkeletonComponent
    ]
})
export class SharedModule {}
