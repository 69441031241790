import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { TrackingService} from './tracking.service';
import { LangISOStore} from './stores/langiso.store';
import { appConfig } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
})
export class LangService {

    private lang: string;
    private readonly nameSess: string;

    constructor(@Inject(DOCUMENT) private _document,
                private translate: TranslateService,
                private trackingService: TrackingService) {
        this.nameSess = appConfig.lang_session;
        this.loadSessionLang();
    }

    loadDefaultLang() {
        const browserlang = navigator.language;
        let langCode = browserlang.split('-')[0];
        if (localStorage.getItem('language')) {
            langCode = localStorage.getItem('language');
        }
        this.translate.setDefaultLang(this.getCodeLang(langCode));
        this.lang = this.getCodeLang(langCode);
        this.setLangHTML();
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'preferences') {
            this.setSessionLang();
        }
    }

    switchLang(lang: string) {
        this.translate.use(lang);
        this.lang = lang;
        this.setLangHTML();
        if (this.trackingService.getPermissionTracking() === 'all' || this.trackingService.getPermissionTracking() === 'preferences') {
            this.setSessionLang();
        }
    }

    getLang() {
        if (localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            this.translate.use(this.lang);
            return this.lang;
        } else {
            this.loadDefaultLang();
            return this.lang;
        }
    }

    getLangTPV() {
        if (localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            for (const lang of LangISOStore) {
                if (lang.lang === this.lang) {
                    return lang.isoCode;
                }
            }
            return '002';
        } else {
            this.loadDefaultLang();
            for (const lang of LangISOStore) {
                if (lang.lang === this.lang) {
                    return lang.isoCode;
                }
            }
            return '002';
        }
    }

    loadSessionLang() {
        if (localStorage.getItem(this.nameSess)) {
            this.lang = localStorage.getItem(this.nameSess);
            this.translate.use(this.lang);
            this.setLangHTML();
        } else {
            this.loadDefaultLang();
        }
    }

    setSessionLang() {
        localStorage.setItem(this.nameSess, this.lang);
    }

    setLangHTML() {
        this._document.getElementById('lang-pyf').setAttribute('lang', this.lang);
    }

    getCodeLang(lang: string) {
        if (LangISOStore.find(langIso => langIso.lang === lang)) {
            return lang;
        } else {
            return 'en';
        }
    }
}
