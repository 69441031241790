import { ActionReducerMap } from '@ngrx/store';

import * as fromFine from './store/fine.reducer';

export interface AppState {
    fine_state: fromFine.FineState;
}

export const appReducers: ActionReducerMap<AppState> = {
    fine_state: fromFine.fineReducer,
};
