import { Component } from '@angular/core';

@Component({
    selector: 'app-apppages',
    templateUrl: './apppages.component.html',
    styles: []
})
export class ApppagesComponent {

}
