import { Component } from '@angular/core';

@Component({
    selector: 'app-footer-widgets',
    templateUrl: './footer-widgets.component.html',
    styleUrls: ['./footer-widgets.component.css']
})
export class FooterWidgetsComponent {

}
