import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { InterceptorSkipHeader } from '../config/config';
import { environment } from '../../environments/environment';
import { LangService } from './lang.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient,
                private authService: AuthService,
                private langService: LangService) {
    }

    getFineData(url: string) {
        const URL_API = environment.URL_API;
        const headers = new HttpHeaders().set('TOKEN', this.authService.getToken());
        return this.http.get(URL_API + url, {headers});
    }

    initPayProcess(url: string) {
        sessionStorage.setItem('pyf_paymnent_response', '1');
        const URL_API = environment.URL_API;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('TOKEN', this.authService.getToken());
        headers = headers.append('consumerlang', this.langService.getLangTPV());
        headers = headers.append(InterceptorSkipHeader, '');
        return this.http.get<any>(URL_API + url, {headers});
    }

    processingPay(url: string) {
        const URL_API = environment.URL_API;
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('TOKEN', this.authService.getToken());
        headers = headers.append(InterceptorSkipHeader, '');
        return this.http.get<any>(URL_API + url, {headers});
    }

}
