import { Component } from '@angular/core';

@Component({
    selector: 'app-sitepages',
    templateUrl: './sitepages.component.html',
    styles: []
})
export class SitepagesComponent {

}
