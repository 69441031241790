import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService, AuthService, TrackingService, LangService, AuthGuard, VerifyTokenGuard, LoaderInterceptor,
    LoaderService, WINDOW_PROVIDERS, RedirectService, TypeaccessloginGuard } from './service.index';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ApiService,
        AuthService,
        TrackingService,
        LangService,
        AuthGuard,
        TypeaccessloginGuard,
        VerifyTokenGuard,
        LoaderService,
        WINDOW_PROVIDERS,
        RedirectService,
        { provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        }
    ],
    declarations: []
})
export class ServiceModule { }
