import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '../../services/window.service';
import { LangService } from '../../services/lang.service';

@Component({
    selector: 'app-footer-bottom-private',
    templateUrl: './footer-bottom-private.component.html',
    styleUrls: ['./footer-bottom-private.component.css']
})
export class FooterBottomPrivateComponent implements OnInit {

    public currentLang: string;
    public currentYear: number;
    public acTiveDropdown = true;

    constructor(@Inject(WINDOW) private window: Window,
                private lang: LangService) {
        this.currentYear = (new Date()).getFullYear();
    }

    ngOnInit() {
        this.currentLang = this.lang.getLang();
    }

    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.currentLang = this.lang.getLang();
        localStorage.setItem('language', lang);
        this.window.location.reload();
    }

}
