import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
    private static UTC_CET: any = '+01:00';
    private static CET_DESCRIPTION: any = 'Central European Time (CET)';
    private static UTC_CEST: any = '+02:00';
    private static CEST_DESCRIPTION: any = 'Central European Summer Time (CEST)';

  constructor() { }

  public static getTimezoneText(timezone: string) {

    switch (timezone) {
        case this.UTC_CET:
          return this.CET_DESCRIPTION;
        case this.UTC_CEST:
          return this.CEST_DESCRIPTION;
        default:
          return this.CET_DESCRIPTION;
    }
  }
}
