import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterceptorSkipHeader } from '../config/config';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    private permission_tc: string;
    private hash = '';

    constructor(private http: HttpClient,
                private authService: AuthService,
                private cookieService: CookieService) {
        this.loadPermissionTracking();
    }

    setPermissionTracking(permission: string) {
        this.permission_tc = permission;
        this.savePermissionTracking();
    }

    savePermissionTracking() {
        localStorage.setItem('permission_pyf_tc', this.permission_tc);
    }

    getPermissionTracking() {
        return this.permission_tc;
    }

    loadPermissionTracking() {
        if (localStorage.getItem('permission_pyf_tc')) {
            this.permission_tc = localStorage.getItem('permission_pyf_tc');
        } else {
            this.cookieService.delete('pyf_cookielaw', '/');
            this.permission_tc = 'waiting';
        }
        this.savePermissionTracking();
    }

    actionEvent(event: any, type: string) {
        const elementId: string = (event.target as Element).id;
        const data = {
            'type': type,
            'info': elementId,
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    returnTPVEvent(event: string, type: string) {
        const data = {
            'type': type,
            'info': event,
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    smsEvent() {
        const data = {
            'type': 'LOGIN',
            'info': 'enterLogin:sms',
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    initLoginEvent() {
        const data = {
            'type': 'LOGIN',
            'info': 'enterLogin',
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    errorEvent(status: string, message: string) {
        const data = {
            'type': 'ERROR',
            'info': status,
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    errorPaymentEvent() {
        const data = {
            'type': 'RETURN_TPV',
            'info': 'pay-tpv-error',
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    successPaymentEvent() {
        const data = {
            'type': 'RETURN_TPV',
            'info': 'pay-tpv-success',
            'datetime': this.getTimeDateNow()
        };
        this.sendTracking(data).subscribe();
    }

    sendTracking(data: any) {
        const hash = (this.hash !== '') ? this.hash : this.authService.getHash();
        const token  = this.isLogged() ? this.authService.getToken() : '';
        const sendData = {
            'hash': hash,
            'token': token,
            'dataTracking': data
        };
        const URL_API = environment.URL_API;
        const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
        headers.set('TOKEN', token);
        return this.http.post<any>(URL_API + 'tracking/', JSON.stringify(sendData), {headers});
    }

    setHash(hash: string) {
        this.hash = hash;
    }

    isLogged() {
        return this.authService.getToken() && this.hash === this.authService.getHash();
    }

    getTimeDateNow() {
        return new Date(moment().tz('Europe/Madrid').format()).toLocaleString();
    }

}
