import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieLawModule } from 'angular2-cookie-law';
import { CookieService } from 'ngx-cookie-service';
import { ResponsiveModule } from 'ngx-responsive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { NgxPopperModule } from 'ngx-popper';
import { NgxCaptchaModule } from './libs/ngx-captcha';
import { FileSaverModule } from 'ngx-filesaver';
import { MomentModule } from 'ngx-moment';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceModule } from './services/service.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ApppagesComponent } from './apppages/apppages.component';
import { SitepagesComponent } from './sitepages/sitepages.component';
import { TinyComponent } from './tiny/tiny.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ApppagesComponent,
        SitepagesComponent,
        TinyComponent
    ],
    exports: [
        FormsModule
    ],
    imports: [
        StoreModule.forRoot(appReducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        ServiceModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        APP_ROUTES,
        CookieLawModule,
        SharedModule,
        ResponsiveModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DeviceDetectorModule.forRoot(),
        NgxCaptchaModule,
        NgxPopperModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            disableTimeOut: false,
            closeButton: true,
            progressBar: true
        }),
        NgbDropdownModule,
        FileSaverModule,
        MomentModule,
        NgxUiLoaderModule
    ],
    providers: [
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
