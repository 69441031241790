import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    private hash: string;

    constructor(private router: Router,
                private authService: AuthService,
                private translate: TranslateService,
                private toastr: ToastrService) {
        this.hash = this.authService.getHash();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.islogged()) {
            return true;
        } else {
            if (this.hash) {
                this.router.navigate([`/app/login/okrac/${this.hash}`]);
                return false;
            } else {
                const url_split = state.url.split('/');
                this.hash = url_split[url_split.length - 1];
                this.router.navigate([`/app/login/okrac/${this.hash}`]);
                const expiredErrMsg: any = this.translate.instant('ErrorsMessages.Err401');
                this.toastr.error(expiredErrMsg);
                return false;
            }
        }
    }

}
