import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SitepagesComponent } from './sitepages/sitepages.component';
import { ApppagesComponent } from './apppages/apppages.component';
import { TinyComponent } from './tiny/tiny.component';

const appRoutes: Routes = [
    {
        path: '',
        component: SitepagesComponent,
        loadChildren: './sitepages/sitepages.module#SitePagesModule'
    },
    {path: 'pyf/:tinyurl', component: TinyComponent},
    {path: 'app/login/okrac/:hash', component: LoginComponent},
    {
        path: '',
        component: ApppagesComponent,
        loadChildren: './apppages/apppages.module#AppPagesModule'
    },
    {path: '**', redirectTo: '/home', pathMatch: 'full'},
    {path: '', redirectTo: '/home', pathMatch: 'full'}

];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, {useHash: false});
