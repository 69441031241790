import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { DOCUMENT} from '@angular/common';
import { WINDOW} from '../../services/window.service';
import { LangService} from '../../services/lang.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent} from '@angular/common';
import disableScroll from 'disable-scroll';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    public show = false;
    public collapse = 'closed';
    public currentLang: string;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    public currentAnchor = 'what';
    public anchors = [
        { id: 'start', class: '' },
        { id: 'what', class: '' },
        { id: 'why', class: '' },
        { id: 'how', class: '' },
        { id: 'faq', class: '' }
    ];
    public acTiveDropdown = false;
    public isMobile = false;

    constructor(private lang: LangService,
                @Inject(DOCUMENT) private _document,
                @Inject(WINDOW) private window: Window,
                private router: Router,
                private location: Location) {
    }

    ngOnInit() {
        this.currentLang = this.lang.getLang();
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationStart) {
                if (ev.url !== this.lastPoppedUrl) {
                    this.yScrollStack.push(window.scrollY);
                }
            } else if (ev instanceof NavigationEnd) {
                if (ev.url === this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else {
                    window.scrollTo(0, 0);
                }
            }
        });
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const main_navbar = this._document.getElementById('main-navbar');
        const offset = this.window.pageYOffset || this._document.documentElement.scrollTop || this._document.body.scrollTop || 0;
        if (offset > 0) {
            main_navbar.classList.add('thickness');
        } else {
            main_navbar.classList.remove('thickness');
        }
    }

    toggleCollapse(anchor?: string, device?: string) {
        if (device === 'mobile') {
            this.isMobile = true;
            this.show = !this.show;
            this.collapse = this.collapse === 'open' ? 'closed' : 'open';
            if (this.collapse === 'open') {
                document.getElementById('overlay').style.display = 'block';
                disableScroll.on();
            } else {
                document.getElementById('overlay').style.display = 'none';
                disableScroll.off();
            }
        } else {
            this.isMobile = false;
            if (anchor !== '') {
                this.setActiveAnchor(anchor);
            }
        }
    }

    setActiveAnchor(anchor: string) {
        this.currentAnchor = anchor;
        const newAnchors = [];
        let addClass = '';
        for (let i = 0; i < this.anchors.length; i++) {
            if (this.anchors[i].id === anchor) {
                if (this.router.url === '/home') {
                    addClass = 'active';
                }
            } else {
                addClass = '';
            }
            const item = {
                id: this.anchors[i].id,
                class: addClass
            };
            newAnchors.push(item);
        }
        this.anchors = newAnchors;
    }

    switchLanguage(lang: string) {
        this.lang.switchLang(lang);
        this.show = !this.show;
        localStorage.setItem('language', lang);
        this.currentLang = lang;
        if (this.isMobile) {
            this.collapse = this.collapse === 'open' ? 'closed' : 'open';
            disableScroll.off();
            document.getElementById('overlay').style.display = 'none';
        }
    }

}

